import React from 'react';

import Layout, {
    bgImageDefault,
    PageHeaderB,
    PageSection,
} from '../components/layout';
import { PageSubtitle, PageText, PageTitle } from '../components/typography';
import { ContactSection } from '../components/contact';
import { graphql } from 'gatsby';
import { ServiceJumpTable } from '../components/servicejumptable';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { makeSectionId } from '../components/jumpboxlink';
import Seo from '../components/seo';

function SecurityTesting({ data, location }) {
    const { t } = useTranslation();
    const text = [
        {
            title: t(
                'pentest_extern_title',
                'Pentest der externen Infrastruktur'
            ),
            desc: t('pentest_extern_desc', 'Ihre Angriffsfläche im Internet'),
        },
        {
            title: t(
                'pentest_intern_title',
                'Pentest der internen Infrastruktur'
            ),
            desc: t('pentest_intern_desc', 'Ihre Angriffsfläche im Intranet'),
        },
        {
            title: t(
                'webanwendungen_sicherheitsueberpruefung_title',
                'Sicherheitsüberprüfung von Webanwendungen und Webservices'
            ),
            desc: t(
                'webanwendungen_sicherheitsueberpruefung_desc',
                'Die Webanwendung - des Hackers bester Freund'
            ),
        },
        {
            title: t(
                'mobile_apps_sicherheitsueberpruefung_title',
                'Sicherheitsüberprüfung von Mobile Apps'
            ),
            desc: t(
                'mobile_apps_sicherheitsueberpruefung_desc',
                'Mobile Smartphone Apps - smart, aber auch sicher?'
            ),
        },
        {
            title: t('stolen_device_review_title', 'Stolen Device Review'),
            desc: t(
                'stolen_device_review_desc',
                'Was passiert, wenn eines unsere Geräte verloren geht oder gestohlen wird?'
            ),
        },
        {
            title: t(
                'attack_breach_simulation_title_short',
                'Attack and Breach Simulation/Red/Purple Teaming'
            ),
            desc: t(
                'attack_breach_simulation_desc_short',
                'Sind wir gegen die Angriffe aktueller Hackergruppen gewappnet?'
            ),
        },
        {
            title: t(
                'ot_scada_security_assessment_title_short',
                'OT/SCADA Security Assessments'
            ),
            desc: t(
                'ot_scada_security_assessment_desc',
                'Sind unsere Industriesteuerungs-Anlagen sicher aufgestellt?'
            ),
        },
        {
            title: t('phishing_simulation_title', 'Phishing Simulation'),
            desc: t(
                'phishing_simulation_desc',
                'Oh, ich habe wirklich ein iPhone gewonnen?'
            ),
        },
    ];
    return (
        <Layout
            location={location}
            bgImage={bgImageDefault.services}
            headerContent={
                <PageHeaderB
                    title={t('title', 'Security Testing')}
                    text={
                        <Trans i18nKey="subtitle">
                            Vertrauen ist gut, Kontrolle ist besser. Durch
                            systematische Sicherheitsanalysen von IT-Systemen,
                            Netzwerken und Anwendungen können Schwachstellen
                            identifiziert und deren Ausnutzung proaktiv
                            verhindert werden.
                        </Trans>
                    }
                />
            }
        >
            <Seo title={t('title', 'Security Testing')} />

            <ServiceJumpTable items={text} />

            <PageSection size="big" id={makeSectionId(text[0].title)}>
                <PageTitle>{text[0].title}</PageTitle>
                <PageSubtitle>{text[0].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="pentest_extern_p1">
                        Von der Homepage über Mail-Server bis hin zu
                        branchenspezifischen Services - fast jede Organisation
                        exponiert Schnittstellen ins Internet, welche Hackern
                        als Angriffspunkte dienen. Doch wissen Sie genau, welche
                        Schnittstellen Ihres Netzwerks aus dem Internet
                        erreichbar sind? Und ob diese angemessen abgesichert
                        sind?
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="pentest_extern_p2">
                        Im Rahmen eines Pentests der externen Infrastruktur
                        durch condignum wird aufgezeigt, welche Angriffspunkte
                        für Hacker aus dem Internet zugänglich sind und wie es
                        um deren Sicherheit bestimmt ist. Sie erhalten eine
                        detaillierte Übersicht Ihrer externen Angriffsfläche,
                        der identifizierten Schwachstellen und Hilfestellungen
                        zu deren Behebung.
                    </Trans>
                </PageText>
            </PageSection>

            <PageSection size="big" id={makeSectionId(text[1].title)} isDark>
                <PageTitle>{text[1].title}</PageTitle>
                <PageSubtitle>{text[1].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="pentest_intern_p1">
                        Dass eine starke Perimeter-Firewall zwar wichtig ist,
                        jedoch bei weitem nicht ausreicht um ein angemessenes
                        Schutzniveau sicherzustellen, ist spätestens seit Beginn
                        des 21. Jahrhunderts klar. Häufig befinden sich
                        Angreifer bereits im internen Netzwerk - externen
                        Personen, die sich durch gezielte Social Engineering
                        Techniken Netzwerkzugang verschaffen oder gar internes
                        Personal, das aus diversen Gründen einen Groll gegen die
                        Organisation hegt. Die Frage ist, wie leicht ist das
                        Spiel für einen internen Angreifer seinen Zugriff
                        auszuweiten, Ihre Kronjuwelen zu stehlen und vielleicht
                        sogar Ihre Domäne zu übernehmen?
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="pentest_intern_p2">
                        Im Rahmen eines Pentests der internen Infrastruktur
                        durch condignum wird aufgezeigt, welche Angriffspunkte
                        für Angreifer innerhalb des Organisationsnetzwerks
                        zugänglich sind und wie es um deren Sicherheit bestimmt
                        ist. Sie erhalten eine detaillierte Übersicht Ihrer
                        internen Angriffsfläche, der identifizierten
                        Schwachstellen und Hilfestellungen zu deren Behebung.
                    </Trans>
                </PageText>
            </PageSection>

            <PageSection size="big" id={makeSectionId(text[2].title)}>
                <PageTitle>{text[2].title}</PageTitle>
                <PageSubtitle>{text[2].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="webanwendungen_sicherheitsueberpruefung_p1">
                        Neben Social-Engineering gehören Webanwendungen und
                        Webservices immer noch zu den prominentesten
                        Einfallstoren für erfolgreiche Hackerangriffe. Aufgrund
                        des wilden Mixes verschiedenster, über die Jahre
                        gewachsener Technologien, können bei der Entwicklung und
                        dem Betrieb von Webanwendungen sehr leicht kleine Fehler
                        passieren. Und Hacker scheuen nicht davor diese für ihre
                        Zwecke zu missbrauchen.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="webanwendungen_sicherheitsueberpruefung_p2">
                        Erfahrene condignum Security Experten analysieren Ihre
                        Webanwendungen und Webservices systematisch um sowohl
                        akute Schwachstellen sowie potenzielle
                        Sicherheitsrisiken aufzudecken und Ihnen detaillierte
                        Hilfestellungen zur zielgerichteten Behebung zur
                        Verfügung zu stellen. So wird Ihr Webangebot proaktiv
                        und angemessen abgesichert und Angreifer beißen sich die
                        Zähne aus.
                    </Trans>
                </PageText>
            </PageSection>

            <PageSection size="big" id={makeSectionId(text[3].title)} isDark>
                <PageTitle>{text[3].title}</PageTitle>
                <PageSubtitle>{text[3].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="mobile_apps_sicherheitsueberpruefung_p1">
                        Viele Aufgaben des täglichen Lebens, sowohl beruflich
                        als auch privat, werden bequem über das Smartphone und
                        entsprechende Mobile Apps erledigt. Doch sind die
                        verwendeten Apps auch sicher? Oder setzen Sie Ihre
                        Geschäfts- und Kundendaten untragbaren Risiken aus?
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="mobile_apps_sicherheitsueberpruefung_p2">
                        Bei einer Mobile App Sicherheitsüberprüfung durch
                        condignum werden sowohl selbst entwickelte als auch
                        gekaufte Apps von erfahrenen Security Experten
                        systematisch untersucht. Neben einer Prüfung der App
                        selbst werden auch alle sicherheitsrelevanten
                        App-Konfigurationsmöglichkeiten, die
                        Kommunikationsverbindungen der App sowie ein etwaiges
                        dahinterstehendes Online-Backend auf akute
                        Schwachstellen und potenzielle Sicherheitsrisiken
                        geprüft. Sie erhalten eine detaillierte Übersicht der
                        Angriffsfläche Ihrer Mobile Apps und detaillierte
                        Hilfestellungen zu deren Absicherung, um die Sicherheit
                        Ihrer Geschäfts- und Kundendaten sicherzustellen.
                    </Trans>
                </PageText>
            </PageSection>

            <PageSection size="big" id={makeSectionId(text[4].title)}>
                <PageTitle>
                    <Trans i18nKey="stolen_device_review_title">
                        Endgeräte-Angreifersimulation / Stolen Device Review
                    </Trans>
                </PageTitle>
                <PageSubtitle>{text[4].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="stolen_device_review_p1">
                        Die immer weiter voranschreitende Dezentralisierung der
                        modernen Arbeitswelt, zu der auch das Arbeiten aus dem
                        Home Office zählt, werden zentrale Unternehmens-Assets
                        an einen deutlich größeren Teil der Mitarbeiter
                        ausgegeben. Diese Geräte behalten diverse schützenswerte
                        Daten oder bieten eine Möglichkeit, auf die zentralsten
                        Ebenen eines Unternehmens zuzugreifen, was zu einem
                        erhöhten Schutzbedarf dieser Geräte führt. Sind Sie sich
                        bewusst, wie widerstandsfähig Ihre mobilen Endgeräte
                        gegen Angreifer mit physikalischen Zugriffen auf diese
                        sind? Sind Ihre Geräte so gehärtet, dass auch im
                        Diebstahlsfalls auf keine schützenswerten Daten
                        zugegriffen werden kann?
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="stolen_device_review_p2">
                        Condignum bietet zur Beantwortung dieser Fragen
                        dedizierte Prüfungen an, bei der die allgemeine
                        Sicherheit dieser Endgeräte geprüft wird. Verschiedene
                        Angriffsszenarien werden hier geprüft und es werden
                        konkrete Verbesserungsvorschläge eingebracht mit dem
                        Ziel die Sicherheit der Endgeräte, und somit die des
                        gesamten Unternehmens, zu erhöhen.
                    </Trans>
                </PageText>
            </PageSection>

            <PageSection size="big" id={makeSectionId(text[5].title)} isDark>
                <PageTitle>
                    <Trans i18nKey="attack_breach_simulation_title">
                        Attack and Breach Simulation/Red/Purple Teaming
                        (Angreifersimulation)
                    </Trans>
                </PageTitle>
                <PageSubtitle>
                    <Trans i18nKey="attack_breach_simulation_desc">
                        Sind wir mit unseren fortschrittlichen
                        IT-Security-Prozesse gegen die Angriffe aktueller
                        Hackergruppen gewappnet?
                    </Trans>
                </PageSubtitle>
                <PageText>
                    <Trans i18nKey="attack_breach_simulation_p1">
                        Ransomware-Gruppen verwenden heutzutage fortschrittliche
                        Technologien und arbeiten in gut organisierten
                        Strukturen zusammen. Sämtliche Angriffstechniken zum
                        Übernehmen der Zielinfrastruktur kommen hier zum
                        Einsatz. Sind in Ihrem Unternehmen technologisch und
                        fachlich alle IT-Security-Prozesse auf diese Bedrohung
                        ausgerichtet und setzen den Angreifern etwas entgegen?
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="attack_breach_simulation_p2">
                        Um diese Fragen zu beantworten bietet die condignum
                        sogenannte Red- und Purple-Teamings auf Basis bekannter
                        Frameworks wie TIBER, DORA und NIST an. Hierbei handelt
                        es sich um eine Methode zur Überprüfung der
                        Informationssicherheit, bei der ein simulierter Angriff
                        auf ein Netzwerk, ein System oder eine Anwendung
                        durchgeführt wird, um potenzielle Sicherheitslücken und
                        Schwächen zu identifizieren.
                    </Trans>
                </PageText>
            </PageSection>

            <PageSection size="big" id={makeSectionId(text[6].title)}>
                <PageTitle>{text[6].title}</PageTitle>
                <PageSubtitle>{text[6].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="ot_scada_security_assessment_p1">
                        Angriffe gegen IT-Infrastruktur im Industrieumfeld sind
                        heutzutage ein relevanter Angriffsvektor geworden. Die
                        Auswirkung erfolgreicher Angriffe auf Steuerungssysteme
                        im Energie- oder Industriesektor ist meistens als sehr
                        kritisch zu bezeichnen, weswegen auch der Gesetzgeber
                        bereits IT-Sicherheitsgesetzte und Verordnungen erlassen
                        hat.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="ot_scada_security_assessment_p2">
                        Condignum bietet deswegen Sicherheitsdienstleistungen im
                        OT-Umfeld an. Wir haben hier langjährige Erfahrung im
                        Ausführen diverser Sicherheitsdienstleitungen - sei es
                        beispielsweise beim Begleiten der Umsetzung einer neuen
                        Umgebung zum Betrieb von OT-Technologie in der
                        Industrie, dem Prüfen der sicheren Konfiguration eines
                        neuen Netzleitsystems im Stromumfeld bis hin zu
                        Penetration Tests an den Schnittstellen von Büro-IT und
                        OT-Umfeld.
                    </Trans>
                </PageText>
            </PageSection>

            <PageSection size="big" id={makeSectionId(text[7].title)} isDark>
                <PageTitle>{text[7].title}</PageTitle>
                <PageSubtitle>{text[7].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="phishing_simulation_p1">
                        Phishing Emails gehören nach wie vor zu den
                        prominentesten Einfallstoren für erfolgreiche
                        Hackerangriffe.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="phishing_simulation_p2">
                        Der gezielte Angriffsversuch über gefälsche E-Mails
                        (engl. Spear Phishing Attack) beschreibt dabei eine
                        Methode des Social Engineerings, mit dem Ziel Passwörter
                        auszuspähen, oder gar Zugriff auf IT-Systeme zu
                        erlangen.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="phishing_simulation_p3">
                        Durch die Simulation von unterschiedlichsten
                        Phishing-Kampagnen (Phishing nach Zugangsdaten,
                        Einbringen von Malware über schadhafte Anhänge oder
                        Downloads etc.) kann die Awareness der Organisation
                        geprüft und nachhaltig gestärkt werden.
                    </Trans>
                </PageText>
            </PageSection>

            <ContactSection requestDemo={false} isDark={false} />
        </Layout>
    );
}

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["security-testing"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default SecurityTesting;
